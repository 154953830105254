import { useEffect } from "react";

const usePreventClickBack = (message?: string, callback?: () => void) => {
  useEffect(() => {
    const preventBack = (event: PopStateEvent) => {
      event.preventDefault();
      if (message) {
        alert(message);
      }
      window.history.pushState(null, "null", window.location.pathname);
    };

    window.history.pushState(null, "null", window.location.pathname);
    window.addEventListener("popstate", preventBack);
    if (callback) callback();

    return () => {
      window.removeEventListener("popstate", preventBack);
    };
  }, []);
};

export default usePreventClickBack;
