import React, { useContext, useEffect, useState } from "react";
import "../styles/waitroom.css";
import { AppContext } from "../App";
import useAlert from "../hooks/useAlert";
import { DummyContainer } from "../components/Layout";
import { useGraphQLApi } from "../hooks/useGraphQLApi";
import HealaWordMark from "../assets/heala-wordmark.svg";
import { useNavigate, useParams } from "react-router-dom";
import { cancelConsultation } from "../graphQL/mutations";
import { useLazyQuery, useMutation } from "@apollo/client";
import usePreventClickBack from "../hooks/usePreventClickBack";
import { ConsultationDetails, StatusType } from "../libs/types";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InstantWaitingRoom from "../components/InstantWaitingRoom";
import ScheduledWaitingRoom from "../components/ScheduledWaitingRoomCard";
import { useNotifyConsultation, useOnsnapshot } from "../utils/firestore";
import {
  philipsHMOIDs,
  philipsHMOLogo,
  preventClickBackMsgs,
  status,
} from "../libs/mock";
import {
  accessConsultation,
  getConsultation,
  getProviderLogo,
} from "../graphQL/queries";
import {
  useConsultationContext,
  useConsultationDispatch,
} from "../contexts/consultationContext";
import {
  ConsultFactory,
  destoryStoredVariables,
  isConsultationTimeReached,
} from "../utils/funcs";

const WaitingRoom = () => {
  localStorage.removeItem("consultationStartTime");
  usePreventClickBack(preventClickBackMsgs.waitingRoom);
  const navigate = useNavigate();
  const { displayAlert } = useAlert();
  const { consultationId } = useParams();
  const snapshotListener = useOnsnapshot();
  const notifyConsultation = useNotifyConsultation();
  const [startTimer, setStartTimer] = useState(false);
  const consultationDetails = useConsultationContext();
  const { initVideoAudioConsultation } = useGraphQLApi();
  const { setLoading, setText } = useContext(AppContext);
  const [cancelConsult] = useMutation(cancelConsultation);
  const updateConsultationDetails = useConsultationDispatch();
  const [providerLogo, setProviderLogo] = useState<string | null>(null);
  const [currentStep, setCurrentStep_] = useState<StatusType | null>(null);

  const [getAccessToken, { error }] = useLazyQuery(accessConsultation, {
    variables: { consultationId },
  });

  const [fetchProviderLogo] = useLazyQuery(getProviderLogo, {
    nextFetchPolicy: "no-cache",
  });

  const [fetchConsultation, { error: consultationError, refetch }] =
    useLazyQuery(getConsultation, {
      nextFetchPolicy: "no-cache",
    });

  const { doctorsId, patientId, consultationType } = React.useMemo(
    () => ConsultFactory(consultationDetails),
    [consultationDetails]
  );

  const setCurrentStep = (value: StatusType) => {
    setCurrentStep_(value);
  };

  //GET ACCESS TOKEN AND STORE IT
  const getAccess = async () => {
    const { data } = await getAccessToken();
    if (data && consultationId) {
      const accessToken = data?.accessConsultation?.access_token;
      sessionStorage.setItem("access_token", `${accessToken}`);
    }
  };

  // FETCH CONSULTATION INFO
  const fetchConsultationInfo = async ({ force = false }) => {
    const func = force ? refetch : fetchConsultation;
    const variables = force
      ? { consultationId }
      : { variables: { consultationId } };

    const response = await func(variables);
    if (response && response.data) {
      const info: ConsultationDetails = response.data?.getConsultation;
      updateConsultationDetails(info);
      const { data = {} } = await fetchProviderLogo({
        variables: { providerId: info?.providerId },
      });
      const { getProvider: providerInfo } = data;

      const companyID = info.companyId;
      const isPhilipsHMO = philipsHMOIDs.includes(companyID as string);
      const icon = isPhilipsHMO ? philipsHMOLogo : providerInfo?.icon;

      sessionStorage.setItem("providerLogo", icon);
      sessionStorage.setItem("createdThrough", info.createdThrough);
      setProviderLogo(icon);
      return info;
    }
  };

  // CHECK THE STAGE OF THE CONSULTATION AND ROUTE ACCORDLY
  const validateConsultStage = async (
    consultationDetails: ConsultationDetails
  ) => {
    const consultCurrentStatus = consultationDetails?.status;
    setCurrentStep(consultCurrentStatus);

    if (["cancelled", "completed", "ongoing"].includes(consultCurrentStatus)) {
      destoryStoredVariables();
    }

    if (consultCurrentStatus === status.cancelled) {
      throw new Error("Consultation cancelled");
    }
    if (consultCurrentStatus === status.completed) {
      throw new Error("Consultation completed");
    }

    if (consultCurrentStatus === status.ongoing) {
      //MORE CHECKS TO KNOW IF TO ROUTE TO ONGOING CALL (IF ANY) OR NOT
    }
  };

  // CALL ON PAGE LOAD TO GET AND STORE ACCESS AND CONSULTATION INFO
  const initApp = async () => {
    try {
      const token = sessionStorage.getItem("access_token");
      const consultation = await (token
        ? fetchConsultationInfo({})
        : getAccess().then(() => fetchConsultationInfo({})));

      consultation &&
        validateConsultStage(consultation).then(() => {
          // INIT VIDEO OR AUDIO CALL
          if (["voice", "video"].includes(consultation?.contactMedium)) {
            initVideoAudioConsultation(`${consultationId}`, false);
          } else {
            sessionStorage.removeItem("agoraInfo");
          }
        });
      return consultation;
    } catch (error) {
      const errMsg = error as Error;
      if (errMsg) displayAlert("error", errMsg?.message);
      console.error(error);
    }
  };

  // CANCEL CONSULTATION ON TIMEOUT
  const endConsultation = async () => {
    try {
      setLoading(true);
      setText("");
      const { data } = await cancelConsult({
        variables: {
          consultationId: consultationId,
          reason:
            "From waiting room: Wait time expired! No doctor joined consultation.",
        },
      });
      if (data) {
        setCurrentStep(status.cancelled);
        destoryStoredVariables();
      }
      setLoading(false);
      setText("");
    } catch (error) {
      displayAlert(
        "error",
        "Something went wrong while trying to end consultation."
      );
      console.error(error);
      setLoading(false);
      setText("");
    }
  };

  // ROUTE TO CONSULTATION MODE WHEN DOCTOR JOINS
  const onTrigger = (contactMedium: string) => {
    const roomId = `${consultationId}_${doctorsId}_${patientId}`;

    if (contactMedium === "chat") {
      navigate(`/chat/${roomId}`);
    }
    if (contactMedium === "voice") {
      navigate(`/voice/${consultationId}`);
    }
    if (contactMedium === "video") {
      navigate(`/video/${consultationId}`);
    }

    if (
      contactMedium !== "video" &&
      contactMedium !== "voice" &&
      contactMedium !== "chat"
    ) {
      navigate(`/chat/${roomId}`);
    }
  };

  // GET ACCESS ON FIRST LOAD && GET CONSULTATION INFO && INIT WAITING ROOM
  useEffect(() => {
    initApp()
      .then((consultation) => {
        if (
          consultation?.type === "scheduled" &&
          consultation?.time &&
          !isConsultationTimeReached(new Date(consultation?.time))
        ) {
          return;
        } else {
          consultation &&
            notifyConsultation(consultation?._id as string)
              .then(() => {
                setStartTimer(true);
              })
              .catch((error) => {
                console.error("Error from notifyConsultation", error);
              });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [consultationId]);

  // LISTENING TO FIRE STORE
  useEffect(() => {
    if (!consultationId) return;
    const unsubscribe = snapshotListener(
      consultationId,
      onTrigger,
      consultationDetails?.contactMedium as string,
      typeof doctorsId === "string",
      () => fetchConsultationInfo({ force: true })
    );

    return () => {
      unsubscribe.then((unsubscribeFn) => unsubscribeFn());
    };
    // remove dependencies
  }, [consultationId, consultationDetails]);

  useEffect(() => {
    if (currentStep === null) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [currentStep]);

  if (error || consultationError) {
    return (
      <DummyContainer>
        <ErrorOutlineIcon
          fontSize="large"
          sx={{ color: "rgb(248 113 113)", marginBottom: "0.5rem" }}
        />
        <p>Something went wrong. Refresh this page.</p>
      </DummyContainer>
    );
  }

  return (
    <div className="text-center">
      <div>
        {currentStep && <ConsultationTitle currentStep={currentStep} />}

        {currentStep && canJoin(currentStep) ? (
          <div className="my-10">
            <div>
              <div className=" flex justify-center mb-6">
                {/* PROVIDER LOGO */}
                <img
                  src={providerLogo || HealaWordMark}
                  alt="provider logo"
                  className=" max-w-[150px] max-h-[80px]"
                />
              </div>

              <div className="flex justify-center mx-4">
                {consultationType === "instant" && (
                  <InstantWaitingRoom
                    startTimer={startTimer}
                    setStartTimer={setStartTimer}
                    cancelConsultation={endConsultation}
                  />
                )}

                {consultationType === "scheduled" && (
                  <ScheduledWaitingRoom
                    endConsultation={endConsultation}
                    startTimer={startTimer}
                    setStartTimer={setStartTimer}
                  />
                )}
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default WaitingRoom;

const statusIn = (
  consultationStatus: string | undefined,
  statuses: StatusType[]
) => statuses.includes(consultationStatus as StatusType);

const canJoin = (v: string | undefined) => {
  return statusIn(v, [status.pending, status.accepted]);
};

const Dict: Record<
  string,
  { heading: string | JSX.Element; content: null | JSX.Element }
> = {
  timeout: {
    heading: "Timeout",
    content: <>No Doctor available at the moment. {"Hello"}</>,
  },
  [status.cancelled]: {
    heading: <>Consultation cancelled!</>,
    content: <>A Doctor didn&apos;t join the consultation.</>,
  },
  [status.completed]: {
    heading: <>Consultation completed!</>,
    content: (
      <>
        Want to talk to a doctor? Start another consultation through your app.{" "}
      </>
    ),
  },
  [status.finished]: {
    heading: <>Rate consultation!!!</>,
    content: null,
  },
  [status.ongoing]: {
    heading: <>Consultation ongoing!</>,
    content: <>consultation is ongoing already...</>,
  },
  [status.declined]: {
    heading: <>Consultation declined!</>,
    content: (
      <>Doctor declined this consultation. Check your email for more details.</>
    ),
  },
};

const ConsultationTitle: React.FC<{
  currentStep: "timeout" | Omit<StatusType, "pending" | "active">;
}> = ({ currentStep }) => {
  const v = Dict[currentStep as string];

  if (!v) return null;

  return (
    <>
      <div className="">
        <div className="info_container mx-auto ">
          <ErrorOutlineIcon
            fontSize="large"
            sx={{ color: "rgb(248 113 113)", marginBottom: "0.5rem" }}
          />
          <h2 className="text-xl text-red-400">{v.heading}</h2>
          {v.content ? <p>{v.content}</p> : null}
        </div>
      </div>
    </>
  );
};
