import React from "react";
import { getInitials } from "../utils/funcs";

const AltProfilePics = ({
  firstName,
  lastName,
  bgColor,
  textColor
}: {
  firstName: string;
  lastName: string;
  bgColor: string;
  textColor: string;
}) => {
  const initials = getInitials(`${firstName} ${lastName}`);
  return (
    <div
      className={`w-[80px] h-[80px] ${bgColor} flex justify-center items-center rounded-full relative z-10`}
    >
      <p className={`${textColor} text-2xl font-semibold`}>{initials}</p>
    </div>
  );
};

export default AltProfilePics;
