import React from "react";

export const TypingIndicator = () => {
  return (
    <div className="typing-container">
      <div className="bg-[#F5F5F5] typing-block space-x-[6px] rounded-tr-2xl rounded-bl-2xl rounded-br-2xl p-6">
        <div className="typing-dot"></div>
        <div className="typing-dot"></div>
        <div className="typing-dot"></div>
      </div>
    </div>
  );
};
