import React from "react";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";

const MutedIcon = () => {
  return (
    <div className="flex justify-center items-center bg-[#fefeff12] text-center rounded-l-full rounded-r-full px-2 lg:px-5 py-1 lg:py-2">
      <div className=" text-red-400 text-xs md:text-sm lg:text-base">
        <VolumeOffIcon />
      </div>
    </div>
  );
};

export default MutedIcon;
