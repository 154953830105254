import { useContext } from "react";
import axios from "axios";
import useAlert from "./useAlert";
import { AppContext } from "../App";
import { useNavigate } from "react-router";
import { isNumber, isString } from "lodash";
import { getConsultation } from "../graphQL/queries";
import { ApolloErrorOptions } from "../libs/interface";
import { useLazyQuery, useMutation } from "@apollo/client";
import { endConsultationReasonsType } from "../libs/types";
import { useConsultationDispatch } from "../contexts/consultationContext";
import {
  END_COMMUNICATION,
  RATE_CONSULTATION,
  cancelConsultation,
  initRtc,
  updatedJoinedConsultation,
} from "../graphQL/mutations";

export const useGraphQLApi = () => {
  const navigate = useNavigate();
  const { displayAlert, getErrorMsg } = useAlert();
  const [generateAgoraInfo] = useMutation(initRtc);
  const uploadUrl = process.env.REACT_APP_UPLOAD_URL;
  const { setLoading, setText } = useContext(AppContext);
  const [cancelConsult] = useMutation(cancelConsultation);
  const [rateConsultation] = useMutation(RATE_CONSULTATION);
  const [endCommunication] = useMutation(END_COMMUNICATION);
  const updateConsultationDetails = useConsultationDispatch();
  const [updateJoinedCall] = useMutation(updatedJoinedConsultation);
  const [fetchConsultationInfo, rest] = useLazyQuery(getConsultation, {
    nextFetchPolicy: "no-cache",
  });

  const useGetConsultationInfo = () => {
    const fetchConsultation = async (
      consultationId: string,
      load?: boolean
    ) => {
      try {
        load && setLoading(true);
        const response = await fetchConsultationInfo({
          variables: { consultationId },
        });
        if (response.data)
          updateConsultationDetails(response.data?.getConsultation);
        load && setLoading(false);
        return response;
      } catch (error) {
        console.error("Error from fetchConsultation: ", error);
        load && setLoading(false);
        //return error;
      }
    };

    return { fetchConsultation, ...rest };
  };

  const sendEndCommunication = async (
    consultationId: string,
    reason: endConsultationReasonsType,
    callback: () => void
  ) => {
    try {
      setText("");
      setLoading(true);
      await endCommunication({
        variables: {
          id: consultationId,
          reason,
        },
      });
      await callback();
      setLoading(false);
      setText("");
    } catch (err) {
      // const errMsg = getErrorMsg(err as ApolloErrorOptions);
      // if (errMsg) displayAlert("error", errMsg);
      console.error("sendError", err);
      await callback();
      setLoading(false);
      setText("");
    }
  };

  const sendRating = async (payload: {
    consultationId: string;
    review: string;
    score: number;
    comment: string;
  }) => {
    try {
      setLoading(true);
      const sentResponse = await rateConsultation({
        variables: {
          ...payload,
        },
      });
      if (sentResponse.data?.rateConsultation?.consultation) {
        setLoading(false);
      }
    } catch (err) {
      const errMsg = getErrorMsg(err as ApolloErrorOptions);
      if (errMsg) displayAlert("error", errMsg);
      console.error("sendError", err);
      setLoading(false);
    }
  };

  const uploadImage = async (
    file: File,
    setProgress?: React.Dispatch<React.SetStateAction<number>>
  ) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const form = new FormData() as any;
      form.append("file", file);
      const data = await axios({
        method: "post",
        url: uploadUrl,
        headers: {
          "Content-Type": `multipart/form-data; boundary=${form?._boundary}`,
        },
        data: form,
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          if (data && data?.total) {
            setProgress?.(Math.round((100 * data.loaded) / data.total));
          }
        },
      });
      return data.data.data.mediaUrl; //data.data.mediaUrl
    } catch (error) {
      const errMsg = getErrorMsg(error as ApolloErrorOptions);
      if (errMsg) displayAlert("error", errMsg);
      // eslint-disable-next-line no-console
      console.error(error);
      setProgress?.(100);
    }
  };

  const cancelCall = (consultationId: string, reasonForFailure: string) => {
    cancelConsult({ variables: { consultationId, reason: reasonForFailure } })
      .then(() => {
        setLoading(false);
        navigate(`/rate-consultation/${consultationId}`);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        navigate(`/rate-consultation/${consultationId}`);
      });
  };

  const patientJoinConsultation = async (
    consultationId: string,
    callback: () => void,
    reasonForFailure: string
  ) => {
    setLoading(true);
    updateJoinedCall({
      variables: {
        consultationId,
        patientJoined: true,
      },
    })
      .then(() => {
        callback();
        setLoading(false);
      })
      .catch((error) => {
        displayAlert(
          "error",
          "Something went wrong, couldn't join the consultation."
        );
        console.error(error);
        cancelCall(consultationId, reasonForFailure);
      });
  };

  // GET AGORA CALL INFO AND STORE IT
  const initVideoAudioConsultation = async (
    consultationId: string,
    load: boolean
  ) => {
    try {
      load && setLoading(true);
      const { data } = await generateAgoraInfo({
        variables: { consultationId },
      });
      const { uid, rtcToken: token } = data.initRtc;

      if (!(isNumber(uid) && isString(token))) {
        displayAlert("error", "Audio/Video Initialization data missing");
        load && setLoading(false);
        return console.warn("Agora Initialization data missing");
      }

      sessionStorage.setItem("agoraInfo", JSON.stringify({ uid, token }));
      load && setLoading(false);
    } catch (error) {
      displayAlert("error", "Initializing Video/Audio consultation Failed");
      console.error("error from init consultation func.", error);
      load && setLoading(false);
    }
  };

  return {
    uploadImage,
    sendEndCommunication,
    sendRating,
    patientJoinConsultation,
    initVideoAudioConsultation,
    useGetConsultationInfo,
  };
};
