import { gql } from "@apollo/client";

export const GET_CONSULTATION_QUERY = gql`
  query getConsultation($id: ID!) {
    getConsultation(id: $id) {
      _id
      patient
      consultationOwner
      description
      firstNotice
      doctor
      doctorData
      patientData
      providerId
      declineReason
      consultationDuration
      contactMedium
    }
  }
`;

export const getConsultation = gql`
  query getConsultation($consultationId: ID!) {
    getConsultation(id: $consultationId) {
      _id
      status
      consultationOwner
      doctor
      doctorData
      patient
      patientData
      companyId
      providerId
      patientJoined
      doctorJoined
      declineReason
      consultationDuration
      contactMedium
      createdThrough
      type
      time
      createdAt
      updatedAt
      referralId
      firstNotice
      description
      discomfortLevel
      symptoms {
        name
      }
      isFollowUp
      followUpConsultationId
      principalHmoId
      pharmacyCode
      pharmacyName
      pharmacyAddress
    }
  }
`;

export const getNotifications = gql`
  query getNotifications($userId: String) {
    getNotifications(user: $userId) {
      data {
        user
        content
        itemId
        ticker
        title
        seen
        tag
        useSound
        role
        saveNotification
        previewImageUri
        previewImageUriThumbnail
        createdAt
        updatedAt
      }
    }
  }
`;

export const accessConsultation = gql`
  query accessConsultation($consultationId: ID!) {
    accessConsultation(id: $consultationId) {
      _id
      email
      dociId
      createdAt
      updatedAt
      isEmailVerified
      access_token
      role
      providerId
      userTypeId
      enrolleeNumber
      isPasswordTemporary
    }
  }
`;

export const getProviderLogo = gql`
  query getProvider($providerId: ID!) {
    getProvider(id: $providerId) {
      _id
      name
      icon
      iconAlt
    }
  }
`;
