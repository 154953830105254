import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 530,
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      gap: 19,
      [theme.breakpoints.down(700)]: {
        width: "100%",
        padding: 0,
        height: "calc(100vh - 42px)",
      },
    },
    paper: {
      width: "100%",
      display: "flex",
      // alignItems: "center",
      flexDirection: "column",
      position: "relative",
      background: "#FFFFFF",
      borderRadius: "16px",
      flex: 1,
      flexGrow: 1,
      overflowY: "scroll",
      // minHeight: 500,
    },
    paper2: {
      width: "100%",
      maxWidth: "500px",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      position: "relative",
    },
    container: {
      width: "100vw",
      height: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    messagesBody: {
      width: "calc( 100% - 20px )",
      margin: 10,
      overflowY: "scroll",
      height: "calc( 100% - 35px )",
    },
    messageTime: {
      fontFamily: "Euclid Circular A",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "15px",
      color: "#757886",
      margin: "20px",
      textAlign: "center",
    },
    endDiv: {
      background: " #FFFFFF",
      borderRadius: "16px",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "25px 27px",
    },
    inputDiv: {
      display: "flex",
      background: " #FFFFFF",
      borderRadius: "16px",
      justifyContent: "space-between",
      padding: "24px 27px",
    },
    flexDocDate: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  })
);
