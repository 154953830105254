import * as React from "react";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  boxSizing: "border-box",
  borderRadius: "10px",
};

export default function MainModal({
  open,
  setOpen,
  children,
  heading,
  modalLabel,
  modalDescription,
  hasCloseBtn,
  ...props
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
  heading: string;
  modalLabel: string;
  modalDescription: string;
  hasCloseBtn: boolean;
}) {
  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby={modalLabel}
        aria-describedby={modalDescription}
        {...props}
      >
        <div className=" h-full flex justify-center items-center m-4">
          <Box sx={style}>
            <Grid
              container
              justifyContent="space-between"
              sx={{ marginBottom: "0.5rem" }}
            >
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {heading}
              </Typography>
              {hasCloseBtn && (
                <button onClick={() => setOpen(false)}>
                  <CloseIcon />
                </button>
              )}
            </Grid>
            <div style={{ height: "100%" }}>{children}</div>
          </Box>
        </div>
      </Modal>
    </div>
  );
}
