import React, { createContext, useContext, useEffect, useState } from "react";
import { ConsultationDetails } from "../libs/types";

type ConsultationContextType = {
  state: ConsultationDetails | undefined;
  setState: React.Dispatch<React.SetStateAction<ConsultationDetails>>;
};

const initialState: ConsultationDetails = JSON.parse(
  `${sessionStorage.getItem("consultationInfo")}`
);

const ConsultationContext = createContext<ConsultationContextType | null>(null);

export const ConsultationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, setState] = useState<ConsultationDetails>(initialState);

  useEffect(() => {
    if (state) {
      sessionStorage.setItem("consultationInfo", JSON.stringify(state));
    }
  }, [state]);

  return (
    <ConsultationContext.Provider value={{ state, setState }}>
      {children}
    </ConsultationContext.Provider>
  );
};

export const useConsultationContext = () => {
  const context = useContext(ConsultationContext);
  return context?.state;
};

export const useConsultationDispatch = () => {
  const context = useContext(ConsultationContext);
  if (context?.setState) return context?.setState;

  return (data: ConsultationDetails) => {
    sessionStorage.setItem("consultationDetails", JSON.stringify(data));
  };
};
