import React from "react";
import "../styles/home.css";
import Logo from "../assets/Logo.svg";
import { DummyContainer } from "../components/Layout";

const StartConsultation = () => {
  return (
    <DummyContainer>
      <div className="logo_div">
        <img src={Logo} alt="HEALA Logo" />
      </div>
      <h1 className="info_heading">Online Consultation</h1>
      <p className="info_para">
        {" "}
        Visit{" "}
        <a className="info_link" href="https://developer.heala.io/">
          developer.heala.io{" "}
        </a>{" "}
        to integrate our consultation API or contact us if you need help with
        integration.
      </p>
    </DummyContainer>
  );
};

export default StartConsultation;
