import { createTheme } from "@mui/material/styles";

export const theme: any = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  colors: {
    primary: {
      main: "#0989e3",
      light: "#63b8ff",
      dark: "#005db0",
      contrastText: "#000",
    },
    secondary: {
      main: "#4db6ac",
      light: "#82e9de",
      dark: "#00867d",
      contrastText: "#000",
    },
  },
};

export default createTheme(theme);
