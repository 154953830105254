import React, { FC } from "react";
import { HiMicrophone } from "react-icons/hi";
import { MdOutlineCallEnd } from "react-icons/md";
import { useCallControlsStyles } from "../../styles/callStyles";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import {
  CallControlsProps,
  ReconnectingCallControlsProps,
} from "../../libs/interface";
import {
  BsFillMicMuteFill,
  BsFillCameraVideoFill,
  BsFillCameraVideoOffFill,
} from "react-icons/bs";

const CallControls: FC<CallControlsProps> = (props) => {
  const classes = useCallControlsStyles();
  const {
    tracks,
    trackState,
    endCommunication,
    activateVideoTrack,
    callType,
    mode,
    mute,
    onHandleClickChat,
  } = props;

  return (
    <div className={classes.root}>
      {/*  SWITCH TO CHAT */}
      <div className="flex flex-col">
        <button
          onClick={() => {
            onHandleClickChat();
          }}
          className=" w-14 h-14 text-[#3E5EA9] text-sm bg-[#FAFAFB] hover:bg-[#3E5EA9] hover:text-white transform transition-all duration-200 p-3 rounded-full"
        >
          <ForumOutlinedIcon />
        </button>
      </div>
      {/*  ======= MUTE AND UNMUTE AUDIO ========= */}
      <button
        className={trackState.audio ? classes.icon : classes.icon}
        onClick={() => mute("audio")}
      >
        {trackState.audio ? (
          <BsFillMicMuteFill size={25} color="#3E5EA9" />
        ) : (
          <HiMicrophone size={25} color="#3E5EA9" />
        )}
      </button>

      {/* ======= MUTE AND UNMUTE VIDEO ======= */}
      <button
        type="button"
        className={`${trackState.video ? classes.icon : classes.icon} mr-2`}
        onClick={() => {
          if (tracks[1] && callType === "video") {
            mute("video");
          } else {
            activateVideoTrack();
          }
        }}
      >
        {trackState.video || mode === "audio" ? (
          <BsFillCameraVideoOffFill size={25} color="#3E5EA9" />
        ) : (
          <BsFillCameraVideoFill size={25} color="#3E5EA9" />
        )}
      </button>

      {/* ======= END CALL ======= */}
      {
        <button className={classes.endBtn} onClick={() => endCommunication()}>
          <MdOutlineCallEnd size={35} color="#fff" />
        </button>
      }
    </div>
  );
};

export const ReconnectingCallControls: FC<ReconnectingCallControlsProps> = (
  props
) => {
  const classes = useCallControlsStyles();
  const { endCommunication } = props;

  return (
    <div className={classes.root}>
      <button className={classes.endBtn} onClick={() => endCommunication()}>
        <MdOutlineCallEnd size={35} color="#fff" />
      </button>
    </div>
  );
};

export default CallControls;
