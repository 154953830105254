import React, { forwardRef, useRef, useState } from "react";
import { Theme } from "@mui/material";
import ImgChat from "../../../../assets/ImgChat.svg";
import SendChat from "../../../../assets/SendChat.svg";
import { createStyles, makeStyles } from "@mui/styles";
import useAutosizeTextArea from "../../../../hooks/useAutosizeTextArea";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapForm: {
      display: "flex",
      justifyContent: "center",
      width: "95%",
      margin: `${theme.spacing(0)} auto`,
    },
    wrapText: {
      width: "100%",
      minHeight: "50px",
      display: "flex",
      alignItems: "center",
      background: "#F6F7FB",
      borderRadius: "40px",
      marginLeft: "1%",
      padding: "1% 5%",
    },
    wrapInput: {
      width: "200%",
      background: "transparent",
      maxHeight: "100px",
      overflowY: "auto",
      resize: "none",
    },
    sendIcon: {
      display: "flex",
      alignItems: "center",
      width: "20%",
    },
    button: {},
  })
);

interface TextInputProps {
  onClick: (inputValue: string) => Promise<"success" | "error">;
  uploadImage: () => void;
  setTyping?: (isTyping: boolean) => void;
}
export const TextInput = forwardRef<HTMLTextAreaElement, TextInputProps>(
  function Input({ onClick, uploadImage, setTyping }, ref) {
    const classes = useStyles();
    const [value, setValue] = useState("");

    const inputRef = useRef<HTMLTextAreaElement>();
    useAutosizeTextArea(inputRef.current, value);

    return (
      <>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            if (inputRef.current) inputRef.current?.focus();
            const res = await onClick(value);
            res === "success" && setValue("");
          }}
          className={classes.wrapForm}
          noValidate
          autoComplete="off"
        >
          <div
            style={{ marginRight: " 3%", display: "flex" }}
            onClick={uploadImage}
          >
            <img src={ImgChat} alt="ChatPlus" />
          </div>

          <div className={classes.wrapText}>
            <textarea
              ref={(el) => {
                inputRef.current = el ?? undefined;
                if (!(el && ref)) return;
                if (typeof ref === "function") ref(el);
                else ref.current = el;
              }}
              autoComplete="off"
              id="standard-text"
              value={value}
              onBlur={() => setTyping && setTyping(false)}
              onInput={() => setTyping && setTyping(true)}
              className={classNames(
                classes.wrapInput,
                "focus:outline-none m-2 focus:shadow-none"
              )}
              onChange={(evt) => setValue(evt.target.value)}
              placeholder="Enter a message"
            />
            <button type="submit" className={classes.sendIcon}>
              <img src={SendChat} alt="SendChat" />
            </button>
          </div>
        </form>
      </>
    );
  }
);
