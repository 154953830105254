import { AgoraRTCError } from "agora-rtc-react";
import { SnackbarKey, useSnackbar } from "notistack";
import { agoraErrorMsgs } from "../libs/mock";
import { ApolloErrorOptions } from "../libs/interface";

const useAlert = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const closeNotification = (id: SnackbarKey | SnackbarKey[]) => {
    if (typeof id === "object") {
      (id || []).map((item) => closeSnackbar(item));
    } else {
      closeSnackbar(id);
    }
  };

  const showErrorMsg = (errorMsg: string) => {
    return enqueueSnackbar(`${errorMsg}`, {
      variant: "error",
      preventDuplicate: true,
      anchorOrigin: {
        horizontal: "right",
        vertical: "top",
      },
      className: "w-[300px]",
      autoHideDuration: 10000,
    });
  };

  const showSuccessMsg = (successMsg: string) => {
    return enqueueSnackbar(`${successMsg}`, {
      variant: "success",
      preventDuplicate: true,
      anchorOrigin: {
        horizontal: "right",
        vertical: "top",
      },
      autoHideDuration: 5000,
    });
  };

  const showWarningMsg = (warningMsg: string, persist = false) => {
    return enqueueSnackbar(`${warningMsg}`, {
      variant: "warning",
      persist,
      preventDuplicate: true,
      anchorOrigin: {
        horizontal: "right",
        vertical: "top",
      },
      autoHideDuration: 5000,
    });
  };

  const showInfoMsg = (infoMsg: string, persist = false) => {
    return enqueueSnackbar(`${infoMsg}`, {
      variant: "info",
      persist,
      preventDuplicate: true,
      anchorOrigin: {
        horizontal: "right",
        vertical: "top",
      },
      autoHideDuration: 5000,
    });
  };

  const getErrorMsg = (error: ApolloErrorOptions) => {
    try {
      if (error?.graphQLErrors && error?.graphQLErrors?.length > 0) {
        const errMsgs = (error?.graphQLErrors || []).map((err) => err.message);
        return errMsgs || "Something went wrong. Try again!!!";
      } else if (error?.networkError) {
        const errMsgs = error?.networkError?.message || "Network Error!";
        return errMsgs || "Something went wrong. Try again!!!";
      } else if (error?.errorMessage) {
        return error.errorMessage;
      }
    } catch (error) {
      console.error("error from get error func.", error);
      return "Something went wrong. Try again!!!";
    }
  };

  const displayAlert = (
    type: "success" | "warning" | "info" | "error",
    message: string | string[],
    persist = false
  ) => {
    switch (type) {
      case "success":
        if (typeof message === "string") return showSuccessMsg(message);
        break;

      case "warning":
        if (typeof message === "string") {
          const warningId = showWarningMsg(message, persist);
          return warningId;
        }
        break;

      case "info":
        if (typeof message === "string") {
          const infoId = showInfoMsg(message, persist);
          return infoId;
        }
        break;

      case "error":
        if (typeof message === "object") {
          return (message || []).map((msg) => showErrorMsg(msg));
        } else {
          return showErrorMsg(message);
        }

      default:
        break;
    }
  };

  const watchFunction = (
    successMsg: string,
    errorMsg: string,
    promise: Promise<unknown>
  ) =>
    promise
      .then((data) => {
        if (!data) {
          displayAlert("error", "Something went wrong, try again.");
          throw Error("Something went wrong in watchFunction");
        }
        displayAlert("success", successMsg);
        return data;
      })
      .catch((error) => {
        const msg = getErrorMsg(error);
        displayAlert("error", msg || errorMsg);
        throw error;
      });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const displayAgoraError = (error: any) => {
    const genericErrMsg = "Something went wrong, try again!";
    if (error?.name?.includes("Agora")) {
      const err = error as AgoraRTCError;
      const errorCode = err.code;
      console.error(`>>> Agora error code: ${errorCode}`);
      const errMsg =
        agoraErrorMsgs[errorCode as keyof typeof agoraErrorMsgs] ||
        genericErrMsg;
      displayAlert("error", errMsg);
    } else {
      displayAlert("error", genericErrMsg);
    }
  };

  return {
    displayAlert,
    watchFunction,
    getErrorMsg,
    displayAgoraError,
    closeNotification,
  };
};
export default useAlert;
