import { gql } from "@apollo/client";

export const cancelConsultation = gql`
  mutation cancelConsultation($consultationId: ID!, $reason: String) {
    cancelConsultation(data: { id: $consultationId, reason: $reason }) {
      consultation {
        _id
        patient
        doctor
        providerId
      }
      errors {
        field
        message
      }
    }
  }
`;

export const initRtc = gql`
  mutation initRtc($consultationId: String) {
    initRtc(data: { consultationId: $consultationId }) {
      uid
      rtcToken
      errors {
        field
        message
      }
    }
  }
`;

export const endCommunication = gql`
  mutation endCommunication($consultationId: ID!) {
    endCommunication(data: { id: $consultationId }) {
      consultation {
        _id
        patient
        time
        type
        appointmentAcceptedAt
        appointmentStartedAt
        consultationDuration
        trackingId
        doctor
        providerId
        createdAt
        updatedAt
      }
      errors {
        field
        message
      }
    }
  }
`;

export const SEND_NOTIFICATION_MUTATION = gql`
  mutation sendNotification(
    $fcmTokens: [String!]
    $users: [String]
    $content: String!
    $itemId: String!
    $ticker: String!
    $title: String!
    $tag: String!
    $useSound: Boolean
    $saveNotification: Boolean
    $previewImageUri: String!
    $previewImageUriThumbnail: String!
  ) {
    sendNotification(
      data: {
        fcmTokens: $fcmTokens
        users: $users
        content: $content
        itemId: $itemId
        ticker: $ticker
        title: $title
        tag: $tag
        useSound: $useSound
        saveNotification: $saveNotification
        previewImageUri: $previewImageUri
        previewImageUriThumbnail: $previewImageUriThumbnail
      }
    ) {
      notification {
        successCount
        failureCount
        multicastId
        failedTokens
      }
      errors {
        field
        message
      }
    }
  }
`;

export const UPDATE_FCM_TOKEN_MUTATION = gql`
  mutation updateFcmToken(
    $userId: String!
    $role: String!
    $token: String!
    $deviceId: String!
  ) {
    updateFcmToken(
      data: { user: $userId, role: $role, token: $token, deviceId: $deviceId }
    ) {
      fcmToken {
        _id
        user
        role
        deviceId
        token
        createdAt
        updatedAt
      }
      errors {
        field
        message
      }
    }
  }
`;

export const END_COMMUNICATION = gql`
  mutation endCommunication($id: ID!, $reason: String!) {
    endCommunication(
      data: { id: $id, patientEndCommunicationReason: $reason }
    ) {
      consultation {
        _id
        patient
        time
        type
        appointmentAcceptedAt
        appointmentStartedAt
        consultationDuration
        trackingId
        doctor
        providerId
        createdAt
        updatedAt
      }
      errors {
        field
        message
      }
    }
  }
`;

export const RATE_CONSULTATION = gql`
  mutation rateConsultation(
    $consultationId: String!
    $review: String!
    $score: Int!
    $comment: String!
  ) {
    rateConsultation(
      data: {
        consultationId: $consultationId
        review: $review
        score: $score
        comment: $comment
      }
    ) {
      consultation {
        _id
        patient
        consultationOwner
        time
        type
        appointmentAcceptedAt
        appointmentStartedAt
        providerId
        createdAt
        updatedAt
      }
      errors {
        field
        message
      }
    }
  }
`;

export const createConsultation = gql`
  mutation createConsultation(
    $consultationOwner: String!
    $patient: String!
    $symptoms: [Symptom!]
    $discomfortLevel: String!
    $description: String!
    $firstNotice: String!
    $type: String
    $status: String
    $providerId: String
    $contactMedium: String
    $doctor: String
  ) {
    createConsultation(
      data: {
        consultationOwner: $consultationOwner
        patient: $patient
        symptoms: $symptoms
        discomfortLevel: $discomfortLevel
        description: $description
        firstNotice: $firstNotice
        type: $type
        status: $status
        providerId: $providerId
        contactMedium: $contactMedium
        doctor: $doctor
      }
    ) {
      consultation {
        _id
      }
      errors {
        field
        message
      }
    }
  }
`;

export const updatedJoinedConsultation = gql`
  mutation updatedJoinedConsultation(
    $consultationId: ID!
    $patientJoined: Boolean
  ) {
    updatedJoinedConsultation(
      data: { id: $consultationId, patientJoined: $patientJoined }
    ) {
      consultation {
        _id
        status
        consultationOwner
        doctor
        doctorData
        patient
        patientData
        providerId
        declineReason
        consultationDuration
        contactMedium
        createdThrough
        type
        time
        createdAt
        updatedAt
        referralId
        firstNotice
        description
        discomfortLevel
        symptoms {
          name
        }
      }
      errors {
        field
        message
      }
    }
  }
`;

export const rebroadcastNotification = gql`
  mutation rebroadcastConsultationNotification($consultationId: String!) {
    rebroadcastConsultationNotification(data: { id: $consultationId }) {
      consultation {
        _id
        patient
        consultationOwner
        time
        type
        appointmentAcceptedAt
        appointmentStartedAt
        trackingId
        notificationSchedules {
          _id
          time
          notified
          consultationId
        }
        symptoms {
          name
        }
        description
        discomfortLevel
        firstNotice
        doctor
        diagnosis {
          ailment
          severity
        }
        doctorNote
        declineReason
        providerId
        createdAt
        updatedAt
      }
      errors {
        field
        message
      }
    }
  }
`;

export const updateSatisfaction = gql`
  mutation updateSatisfaction(
    $consultationId: ID!
    $isSatisfied: Boolean
    $reason: String
    $disputeReason: String
  ) {
    updateSatisfaction(
      data: {
        id: $consultationId
        patientSatisfied: $isSatisfied
        patientSatisfactionReason: $reason
        disputeReason: $disputeReason
      }
    ) {
      consultation {
        _id
        patient
        consultationOwner
        symptoms {
          name
        }
        description
        discomfortLevel
        firstNotice
        createdAt
        time
        type
        appointmentAcceptedAt
        appointmentStartedAt
        trackingId
        patientJoined
        doctorJoined
        notificationSchedules {
          _id
          time
          notified
          consultationId
        }
        status
        updatedAt
        contactMedium
        providerId
        patientSatisfied
        doctorSatisfied
      }
      errors {
        field
        message
      }
    }
  }
`;

export const resolveDispute = gql`
  mutation resolveDispute(
    $consultationId: ID!
    $disputeStatus: String
    $reason: String
    $isDisputed: Boolean
  ) {
    resolveDispute(
      data: {
        id: $consultationId
        disputeStatus: $disputeStatus
        disputeResolvedReason: $reason
        isDisputed: $isDisputed
      }
    ) {
      consultation {
        _id
        patient
        consultationOwner
        time
        type
        appointmentAcceptedAt
        patientEndCommunicationReason
        appointmentStartedAt
        consultationDuration
        trackingId
        disputeStatus
        notificationSchedules {
          _id
          time
          notified
          consultationId
        }
        symptoms {
          name
        }
        description
        discomfortLevel
        firstNotice
        doctor
        diagnosis {
          ailment
          severity
        }
        doctorNote
        declineReason
        providerId
        createdAt
        updatedAt
      }
      errors {
        field
        message
      }
    }
  }
`;

// isDisputed: true
export const createDispute = gql`
  mutation createDispute($consultationId: ID!, $disputeReason: String) {
    createDispute(
      data: { id: $consultationId, disputeReason: $disputeReason }
    ) {
      consultation {
        _id
        patient
        consultationOwner
        time
        type
        appointmentAcceptedAt
        patientEndCommunicationReason
        appointmentStartedAt
        consultationDuration
        trackingId
        notificationSchedules {
          _id
          time
          notified
          consultationId
        }
        symptoms {
          name
        }
        description
        discomfortLevel
        firstNotice
        doctor
        diagnosis {
          ailment
          severity
        }
        doctorNote
        declineReason
        providerId
        createdAt
        updatedAt
      }
      errors {
        field
        message
      }
    }
  }
`;
