/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from "react";
import useAlert from "./useAlert";
import { SnackbarKey } from "notistack";
import useEvent from "react-use-event-hook";
import AgoraRTC, {
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  ILocalVideoTrack,
  ILocalAudioTrack,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
  ConnectionState,
  UID,
} from "agora-rtc-sdk-ng";

const localTracks: {
  videoTrack?: ICameraVideoTrack;
  audioTrack?: IMicrophoneAudioTrack;
} = { videoTrack: undefined, audioTrack: undefined };

let prevNetworkQuality: number;
let poorAlertId: SnackbarKey | SnackbarKey[] | undefined;
let unstableAlertId: SnackbarKey | SnackbarKey[] | undefined;

export default function useAgora(client: IAgoraRTCClient | undefined): {
  localAudioTrack: ILocalAudioTrack | undefined;
  localVideoTrack: ILocalVideoTrack | undefined;
  joinState: boolean;
  setJoinState: React.Dispatch<React.SetStateAction<boolean>>;
  muteAudio: () => void;
  muteVideo: () => void;
  leave: () => Promise<void>;
  join: (
    appid: string,
    channel: string,
    token: string,
    uid: string | number | null,
    type: "audio" | "video"
  ) => void;
  remoteUsers: IAgoraRTCRemoteUser[];
  activateVideo: () => Promise<void>;
} {
  const [joinState, setJoinState] = useState(false);
  const [status, setStatus] = useState<ConnectionState>("DISCONNECTED");
  const { displayAgoraError, displayAlert, closeNotification } = useAlert();
  const options: { uid: number | string | null } & Record<string, any> = {
    appid: null,
    channel: null,
    uid: null,
    token: null,
  };
  const [remoteUsers, setRemoteUsers] = useState<IAgoraRTCRemoteUser[]>([]);
  const [localVideoTrack, setLocalVideoTrack] = useState<
    ILocalVideoTrack | undefined
  >(undefined);
  const [localAudioTrack, setLocalAudioTrack] = useState<
    ILocalAudioTrack | undefined
  >(undefined);

  async function join(
    appid: string,
    channel: string,
    token: string,
    uid: string | number | null,
    type: "audio" | "video" = "video"
  ) {
    try {
      if (!client) throw Error("Error fetching client.");

      //const isAudioTrack =
      if (type === "video") {
        // create audio and video tracks and store
        const [audioTrack, videoTrack] = await Promise.all([
          AgoraRTC.createMicrophoneAudioTrack(),
          AgoraRTC.createCameraVideoTrack(),
        ]);

        localTracks.audioTrack = audioTrack;
        localTracks.videoTrack = videoTrack;
        setLocalAudioTrack(localTracks.audioTrack);
        setLocalVideoTrack(localTracks.videoTrack);
      }

      if (type === "audio") {
        // create only audio track and store
        const audioTrack = await AgoraRTC.createMicrophoneAudioTrack();

        localTracks.audioTrack = audioTrack;
        setLocalAudioTrack(localTracks.audioTrack);
      }

      // then join Agora channel
      if (status === "DISCONNECTED" || status === "DISCONNECTING") {
        const uuid = await client.join(appid, channel, token || null, uid);
        options.uid = uuid;
      }

      // then publish
      if (type === "video") {
        await client.publish(Object.values(localTracks));
      }

      if (type === "audio" && localTracks["audioTrack"]) {
        await client.publish([localTracks["audioTrack"]]);
      }

      (window as any).client = client;
      if (localTracks.videoTrack) {
        (window as any).videoTrack = localTracks.videoTrack;
      }

      setJoinState(true);
    } catch (error) {
      leave();
      displayAgoraError(error);
      console.error(`>>> Error from join func. in useAgora`, error);
    }
  }

  const activateVideo = async () => {
    try {
      if (!client) throw Error("Error fetching client.");
      if (localTracks.videoTrack) {
        setLocalVideoTrack(localTracks.videoTrack);
        await client.publish([localTracks["videoTrack"]]);
        (window as any).videoTrack = localTracks.videoTrack;
      } else {
        const videoTrack = await AgoraRTC.createCameraVideoTrack();

        localTracks.videoTrack = videoTrack;
        setLocalVideoTrack(localTracks.videoTrack);

        await client.publish([localTracks["videoTrack"]]);

        (window as any).videoTrack = localTracks.videoTrack;
      }
    } catch (error) {
      displayAgoraError(error);
      console.error(`>>>Error from activateVideo func. in useAgora`, error);
    }
  };

  async function leave() {
    try {
      if (localTracks["audioTrack"]) {
        localTracks["audioTrack"].stop();
        localTracks["audioTrack"].close();
      }
      if (localTracks["videoTrack"]) {
        localTracks["videoTrack"].stop();
        localTracks["videoTrack"].close();
      }

      await client?.leave();
      setRemoteUsers([]);
      setJoinState(false);
    } catch (error) {
      displayAgoraError(error);
      console.error(`>>> Error from leave func. in useAgora`, error);
    }
  }

  async function muteAudio() {
    if (localAudioTrack) {
      await localAudioTrack.setMuted(true);
    }
  }

  async function muteVideo() {
    if (localVideoTrack) {
      await localVideoTrack.setMuted(true);
    }
  }

  const handleUserPublished = useEvent(
    async (user: IAgoraRTCRemoteUser, mediaType: "audio" | "video") => {
      try {
        if (!client) return;
        await client.subscribe(user, mediaType);
        // toggle rerender while state of remoteUsers changed.
        setRemoteUsers(() => Array.from(client.remoteUsers));
        if (mediaType === "audio") {
          user.audioTrack?.play();
        }
      } catch (error) {
        //displayAgoraError(error);
        console.error(">>>", error);
      }
    }
  );

  const handleUserUnpublished = useEvent(
    async (user: IAgoraRTCRemoteUser, mediaType) => {
      try {
        if (!client) return;
        await client.unsubscribe(user, mediaType); // new

        setRemoteUsers(() => {
          return Array.from(client.remoteUsers);
        });
        if (mediaType === "audio") {
          user.audioTrack?.stop();
        }
      } catch (error) {
        //displayAgoraError(error);
        console.error(">>>", error);
      }
    }
  );

  const handleUserJoined = useEvent(() => {
    if (!client) return;
    setRemoteUsers(() => Array.from(client.remoteUsers));
  });

  const handleUserLeft = useEvent((user: IAgoraRTCRemoteUser, reason) => {
    if (!client) return;
    if (reason === "Quit") {
      console.log("call ended!");
    }
    setRemoteUsers(() => Array.from(client.remoteUsers));
  });

  const onConnectionStateChange = useEvent(
    (state: ConnectionState /* prevState, reason */) => {
      setStatus(state);
    }
  );

  const onException = useEvent(
    (event: { code: number; msg: string; uid: UID }) => {
      const { code, msg, uid } = event;
      if (uid !== client?.uid) return;
      console.error(`>>> Error ${code}: ${msg}`);
      // if (msg.includes("AUDIO") && msg.includes("LOW")) return;
      // const errMsg = msg.split("_").join(" ").toLowerCase();
      // displayAlert("error", `${errMsg}`);
    }
  );

  const onNetworkChange = useEvent(
    (stats /* uplinkNetworkQuality downlinkNetworkQuality */) => {
      const { uplinkNetworkQuality } = stats;

      if (prevNetworkQuality === uplinkNetworkQuality) return;

      if (uplinkNetworkQuality < 4) {
        if (poorAlertId) closeNotification(poorAlertId);
        if (unstableAlertId) closeNotification(unstableAlertId);
        poorAlertId = undefined;
        unstableAlertId = undefined;
      }

      // if (uplinkNetworkQuality === 4) {
      //   if (poorAlertId) closeNotification(poorAlertId);
      //   const notificationId = displayAlert(
      //     "info",
      //     "Unstable networking connection!",
      //     true
      //   );
      //   unstableAlertId = notificationId;
      // }

      if (uplinkNetworkQuality > 4) {
        if (unstableAlertId) closeNotification(unstableAlertId);
        const notificationId = displayAlert(
          "warning",
          "Poor networking connection!",
          true
        );
        poorAlertId = notificationId;
      }

      if (uplinkNetworkQuality === 6) {
        if (unstableAlertId) closeNotification(unstableAlertId);
        const notificationId = displayAlert(
          "error",
          "No internet connection!",
          true
        );
        poorAlertId = notificationId;
      }

      prevNetworkQuality = uplinkNetworkQuality;
    }
  );

  useEffect(() => {
    if (!client) return;
    try {
      setRemoteUsers(client.remoteUsers);

      client.on("user-published", handleUserPublished);
      client.on("user-unpublished", handleUserUnpublished);
      client.on("user-joined", handleUserJoined);
      client.on("user-left", handleUserLeft);
      client.on("connection-state-change", onConnectionStateChange);
      client.on("exception", onException);
      client.on("network-quality", onNetworkChange);
    } catch (error) {
      displayAgoraError(error);
      console.error(">>> Error from UE in useAgora", error);
    }

    return () => {
      client.off("user-published", handleUserPublished);
      client.off("user-unpublished", handleUserUnpublished);
      client.off("user-joined", handleUserJoined);
      client.off("user-left", handleUserLeft);
      client.off("connection-state-change", onConnectionStateChange);
      client.off("exception", onException);
      client.off("network-quality", onNetworkChange);
    };
  }, [client]);

  return {
    localAudioTrack,
    localVideoTrack,
    joinState,
    setJoinState,
    muteAudio,
    muteVideo,
    leave,
    join,
    remoteUsers,
    activateVideo,
  };
}
