import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

/* ========= CALL PAGE STYLES =========== */
export const useCallStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      flex: 1,
      height: "100vh",
      position: "absolute",
      backgroundColor: "#313131",
    },
    paper: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-end",
      flexDirection: "column",
    },
    videoContainer: {
      flex: 1,
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: 16,
      justifyItems: "center",
      alignItems: "center",
      paddingTop: theme.spacing(2),
      "& div > video": {
        background: "#313131",
      },
      "& div": {
        maxWidth: 600,
        [theme.breakpoints.down("sm")]: {
          maxWidth: "100%",
          width: "100%",
          height: "100%",
        },
      },
      [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: "auto",
        paddingTop: theme.spacing(0),
      },
    },
  })
);

/* ======== CALL CONTROLS ========== */
export const useCallControlsStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: theme.spacing(3),
      /* padding: theme.spacing(6, 0), */
      /* [theme.breakpoints.down("sm")]: {
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 100,
      }, */
    },
    icon: {
      width: "56px",
      height: "56px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#FAFAFB",
      borderRadius: "100%",
      cursor: "pointer",
      border: "none",
    },
    endBtn: {
      width: "56px",
      height: "56px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#EA4335",
      borderRadius: "100%",
      cursor: "pointer",
    },
  })
);
