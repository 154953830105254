import { createStyles, makeStyles } from "@mui/styles";
import { deepOrange } from "@mui/material/colors";
import { Theme } from "@mui/material";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    messageRow: {
      display: "flex",
      paddingBottom: theme.spacing(2),
    },
    messageRowRight: {
      display: "flex",
      justifyContent: "flex-end",
    },
    messageBlue: {
      // maxWidth: "65%",

      marginLeft: "4%",
      marginTop: "1%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      padding: "10px 20px",

      width: "fit-content",
      height: "fit-content",
      left: "84px",
      top: " 434.76px",

      background: "#F5F5F5",
      borderRadius: " 0px 16px 16px 16px",

      "&:after": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "15px solid #A8DDFD",
        borderLeft: "15px solid transparent",
        borderRight: "15px solid transparent",
        top: "0",
        left: "-15px",
        display: "none",
      },
      "&:before": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "17px solid #97C6E3",
        borderLeft: "16px solid transparent",
        borderRight: "16px solid transparent",
        top: "-1px",
        left: "-17px",
        display: "none",
      },
    },
    messageContentL: {
      padding: 0,
      margin: 0,

      fontFamily: "Euclid Circular A",
      fontStyle: "normal",
      fontSeight: "400",
      fontSize: "14px",
      lineHeight: "160%",

      color: "#000",
    },
    messageTimeStampLeft: {
      bottom: "-1px",
      right: "5px",

      fontFamily: "Euclid Circular A",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "12px",
      lineHeight: "15px",

      color: "#9A96A4",
      textAlign: "right",
    },
    messageOrange: {
      maxWidth: "65%",

      position: "relative",
      marginRight: "20px",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      padding: "10px 20px",

      width: "fit-content",
      height: "fit-content",
      marginBottom: "2%",

      /* Primary Blue */

      background: "#3E5EA9",
      borderRadius: "16px 0px 16px 16px",

      /* Inside auto layout */

      flex: "none",
      order: "0",
      flexGrow: "0",
      "&:after": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "15px solid #f8e896",
        borderLeft: "15px solid transparent",
        borderRight: "15px solid transparent",
        top: "0",
        right: "-15px",
        display: "none",
      },
      "&:before": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "17px solid #dfd087",
        borderLeft: "16px solid transparent",
        borderRight: "16px solid transparent",
        top: "-1px",
        right: "-17px",
        display: "none",
      },
    },

    messageContentR: {
      padding: 0,
      margin: 0,

      fontFamily: "Euclid Circular A",
      fontStyle: "normal",
      fontSeight: "400",
      fontSize: "14px",
      lineHeight: "160%",

      color: "#FFFFFF",
    },
    messageTimeStampRight: {
      bottom: "-1px",
      right: "5px",

      fontFamily: "Euclid Circular A",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "12px",
      lineHeight: "15px",

      color: "#fff",
    },

    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    avatarNothing: {
      color: "transparent",
      backgroundColor: "transparent",
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    displayName: {
      marginLeft: "20px",
    },
    imgUpload: {
      width: "100%",
      height: 200,
    },
  })
);
