/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import Lottie from "lottie-react";
import SuccessLottie from "../assets/lotties/success.json";

export const Success = () => {
  const createdThrough = sessionStorage.getItem("createdThrough");
  const isDoctorDirect = createdThrough === "doctor-direct";
  const baseUrl = process.env.REACT_APP_VHP_BASE_URL;

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Lottie
        animationData={SuccessLottie}
        loop={false}
        style={{ marginBottom: 20, width: 150 }}
      />
      {isDoctorDirect ? (
        <a href={`${baseUrl}access`} rel="noreferrer">
          <button className="text-[#3E5EA9] cursor-pointer underline">
            Click to go back to dashboard
          </button>
        </a>
      ) : (
        <p style={{ textAlign: "center" }}>Close window or return to app.</p>
      )}
    </div>
  );
};
