/* eslint-disable indent */
import {
  ConsultationDetails,
  ConsultationFactory,
  ConsultationType,
  TypeOfRetry,
  doctorData,
  patientData,
} from "../libs/types";
import {
  IAgoraRTCRemoteUser,
  IRemoteAudioTrack,
  IRemoteVideoTrack,
} from "agora-rtc-sdk-ng";
import { format, isAfter, isEqual } from "date-fns";
import {
  MessagePayload /* MessageState, ReplyToPayload */,
  ReplyToPayload,
} from "./firebaseTypes";

export const destoryStoredVariables = () => {
  const keys = [
    "rateConsultationStage",
    "waitingRoomStartTime",
    "consultationInfo",
    "providerLogo",
    "access_token",
    "consultationDetails",
    "agoraInfo",
  ];
  localStorage.clear();
  keys.forEach((key) => sessionStorage.removeItem(key));
};

export const createAgoraUser = (user: IAgoraRTCRemoteUser) => {
  const _user = {
    uid: user.uid,
    audio: user.hasAudio
      ? {
          track: user.audioTrack,
          muted: false,
        }
      : undefined,
    video: user.hasVideo
      ? {
          track: user.videoTrack,
          muted: false,
        }
      : undefined,
  };

  return _user;
};

export const createCustomRemoteTracks = (
  remoteVideoAudioTrack: [IRemoteAudioTrack, IRemoteVideoTrack]
) => {
  const customRemoteVideoTrack = {
    name: "remoteTracks",
    audioTrack: remoteVideoAudioTrack[0],
    videoTrack: remoteVideoAudioTrack[1],
  };
  return customRemoteVideoTrack;
};

export const createCustomLocalTracks = (
  remoteVideoAudioTrack: [IRemoteAudioTrack, IRemoteVideoTrack]
) => {
  const customRemoteVideoTrack = {
    name: "localTracks",
    audioTrack: remoteVideoAudioTrack[0],
    videoTrack: remoteVideoAudioTrack[1],
  };
  return customRemoteVideoTrack;
};

export const getInitials = (name: string) => {
  try {
    const splitedNamesArr = name.split(" ");

    const initailsArr = splitedNamesArr.map((name) => {
      const splitedNameArr = name.split("");
      return splitedNameArr[0];
    });

    return initailsArr.join("");
  } catch (error) {
    console.error("error from getInitials func.", error);
    return "";
  }
};

export const ConsultFactory: ConsultationFactory = (
  info: ConsultationDetails | undefined
) => {
  if (!info)
    return {
      _id: null,
      patientId: "",
      doctorsId: null,
      conDate: "",
      conTime: "",
      consultationType: null,
      type: null,
      time: null,
      providerId: null,
      patientData: {} as patientData,
      patientJoined: false,
      doctorJoined: false,
      doctorData: {} as doctorData,
      contactMedium: null,
      isFollowUp: false,
    };

  const infoDate = info?.createdAt;
  const type: ConsultationType = info?.type;
  const datePlusOneHour = info?.createdAt
    ? new Date((infoDate || "") as Date)
    : null;

  const conTime = datePlusOneHour ? `${format(datePlusOneHour, "p")}` : "";
  const conDate = datePlusOneHour
    ? `${format(datePlusOneHour, "ccc")} ${format(datePlusOneHour, "PPP")}`
    : "";

  return {
    _id: info?._id as string,
    patientId: info?.patient || "",
    doctorsId: info?.doctor || null,
    conDate,
    conTime,
    consultationType: info?.type,
    type,
    time: info?.time,
    providerId: info?.providerId,
    patientData: info?.patientData || ({} as patientData),
    patientJoined: info?.patientJoined || false,
    doctorJoined: info?.doctorJoined || false,
    doctorData: info?.doctorData || ({} as doctorData),
    contactMedium: info?.contactMedium,
    isFollowUp: info?.isFollowUp || false,
  };
};

export const isConsultationTimeReached = (scheduledConsultationTime: Date) =>
  isEqual(Date.now(), scheduledConsultationTime) ||
  isAfter(Date.now(), scheduledConsultationTime);

export const createConsultationVar = (
  prevConsultationInfo: ConsultationDetails,
  type: TypeOfRetry
) => {
  const symptoms = (prevConsultationInfo?.symptoms || []).map((symptoms) => {
    return { name: symptoms.name };
  });
  const doctor = type === "same_doctor" ? prevConsultationInfo.doctor : null;
  return {
    consultationOwner: "Myself",
    patient: prevConsultationInfo?.patient,
    symptoms,
    discomfortLevel: prevConsultationInfo?.discomfortLevel,
    description: prevConsultationInfo?.description,
    firstNotice: prevConsultationInfo?.firstNotice,
    type: prevConsultationInfo?.type,
    status: "pending",
    providerId: prevConsultationInfo?.providerId,
    contactMedium: prevConsultationInfo?.contactMedium,
    createdThrough: "api",
    doctor,
  };
};

export const isDevelopment = () => {
  return process.env.NODE_ENV === "development";
};

export const isImageUrl = (url: string) => {
  const regex = /^https?:\/\/.*\/.*\.(png|gif|webp|jpeg|jpg)\??.*$/gim;
  let result;
  if (url.match(regex)) {
    result = {
      match: url.match(regex),
    };
  } else {
    result = false;
  }
  return result;
};

export const isPdfUrl = (url: string) => {
  const regex = /^https?:\/\/.*\/.*\.(pdf)\??.*$/gim;
  return regex.test(url);
};

export function guessMode(
  mode: Record<
    "doctor" | "patient",
    null | "accepted" | "rejected" | "chat" | "video" | "voice"
  >
) {
  const { doctor: doctorMode, patient: patientMode } = mode;

  if (doctorMode === "chat") {
    if (patientMode === null) return "request_for_chat";
    if (patientMode === "accepted") return "switch_to_chat";
  }

  if (doctorMode === "video") {
    if (patientMode === null) return "request_for_video";
    if (patientMode === "accepted") return "switch_to_video";
  }

  if (doctorMode === "voice") {
    if (patientMode === null) return "request_for_voice";
    if (patientMode === "accepted") return "switch_to_voice";
  }

  if (doctorMode === "accepted") {
    if (patientMode === "voice") return "switch_to_voice";
    if (patientMode === "video") return "switch_to_video";
    if (patientMode === "chat") return "switch_to_chat";
  }

  if (doctorMode === "rejected") {
    if (
      patientMode === "voice" ||
      patientMode === "video" ||
      patientMode === "chat" ||
      patientMode === null
    )
      return "declined";
  }

  if (doctorMode === null) {
    if (
      patientMode === "voice" ||
      patientMode === "video" ||
      patientMode === "chat"
    )
      return "requesting";
  }

  return "unknown";
}

export const sortByDate = (values: MessagePayload[]) => {
  try {
    return values.sort((current, prev) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const currentDate = new Date(current?.timestamp?.toDate()) as any;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const prevDate = new Date(prev.timestamp.toDate()) as any;
      return currentDate - prevDate;
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return values;
  }
};

export const isMsgFromPatient = (message: MessagePayload, patientId: string) =>
  message.idFrom === patientId;

export const scrollToRepliedMsg = async (
  msgId: string,
  alignedLeft: boolean
) => {
  try {
    const contentId = `msg_${msgId}`;
    const el = document.getElementById(contentId);
    const msgCont = document.getElementById("style-1");
    const className = alignedLeft ? "shake-left" : "shake-right";
    if (el) {
      msgCont?.scrollTo({
        top: el.offsetTop,
        behavior: "smooth",
      });

      el.classList.add(className);
      setTimeout(() => {
        el.classList.remove(className);
      }, 2000);
    }
  } catch (error) {
    console.error("couldn't scroll to message container", error);
  }
};

export const generateReplyToPayload = (
  replyTo: MessagePayload
): ReplyToPayload => {
  return {
    _id: replyTo._id,
    content: replyTo.content,
    idFrom: replyTo.idFrom,
    timestamp: replyTo.timestamp,
  };
};

export const truncate = (string: string, length: number) => {
  const strArr = string.split("");
  if (strArr.length <= length) {
    return string;
  } else {
    return `${strArr.slice(0, length).join("")}...`;
  }
};
