import React, { useState, createRef } from "react";
import "./App.css";
import theme from "./theme";
import Lottie from "lottie-react";
import { Chat } from "./pages/Chat";
import * as Sentry from "@sentry/react";
import NotFound from "./pages/NotFound";
import Layout from "./components/Layout";
import { Success } from "./pages/Success";
import WaitingRoom from "./pages/WaitingRoom";
import VideoAudioApp from "./pages/VideoAudioApp";
import RateConsultation from "./pages/RateConsultation";
import SuccessLottie from "./assets/lotties/loading.json";
import StartConsultation from "./pages/StartConsultation";
import { SnackbarKey, SnackbarProvider } from "notistack";
import { Backdrop, ThemeProvider, Typography } from "@mui/material";
import { StylesProvider, createGenerateClassName } from "@mui/styles";
import { ConsultationProvider } from "./contexts/consultationContext";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const generateClassName = createGenerateClassName({
  productionPrefix: "c",
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <StartConsultation />,
  },
  {
    path: "/:consultationId",
    element: <WaitingRoom />,
  },
  {
    path: "/voice/:consultationId",
    element: <VideoAudioApp />,
  },
  {
    path: "/video/:consultationId",
    element: <VideoAudioApp />,
  },
  {
    path: "/chat/:roomId",
    element: <Chat />,
  },
  {
    path: "/rate-consultation/:consultationId",
    element: <RateConsultation />,
  },
  {
    path: "/retry/:consultationId",
    element: <RateConsultation />,
  },
  {
    path: "/success",
    element: <Success />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

const notistackRef: React.RefObject<SnackbarProvider> = createRef();
const onClickDismiss = (key: SnackbarKey | undefined) => () => {
  if (notistackRef?.current) notistackRef?.current?.closeSnackbar(key);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AppContext = React.createContext<any>(null);

function App() {
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const [showAwaitingRes, setShowAwaitingRes] = useState(false);

  function clearCacheStorage() {
    if (!("caches" in window)) return;
    caches.keys().then(function (names) {
      for (const name of names) {
        caches.delete(name);
        window.location.reload();
      }
    });
  }

  clearCacheStorage();

  return (
    <SnackbarProvider
      ref={notistackRef}
      maxSnack={3}
      action={(key) => (
        <Typography
          onClick={onClickDismiss(key)}
          style={{
            fontSize: "0.8rem",
            color: "ffffff",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          Dismiss
        </Typography>
      )}
    >
      <StylesProvider generateClassName={generateClassName}>
        <ThemeProvider theme={theme}>
          <AppContext.Provider
            value={{
              loading,
              setLoading,
              setText,
              showAwaitingRes,
              setShowAwaitingRes,
            }}
          >
            <ConsultationProvider>
              <Layout>
                <RouterProvider router={router} />
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={loading}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 25,
                      flexDirection: "column",
                    }}
                  >
                    <Typography variant="h3" component="h3">
                      {text}
                    </Typography>
                    <Lottie
                      animationData={SuccessLottie}
                      style={{ marginBottom: 20, width: 25 }}
                    />
                  </div>
                </Backdrop>
              </Layout>
            </ConsultationProvider>
          </AppContext.Provider>
        </ThemeProvider>
      </StylesProvider>
    </SnackbarProvider>
  );
}

export default Sentry.withProfiler(App);
