import React from "react";

type Props = {
  children: React.ReactNode;
};

const Layout: React.FC<Props> = ({ children }) => {
  return (
    <div
      id="layout_main_container"
      className="w-full min-h-screen flex justify-center items-center "
    >
      <div className="w-full">{children}</div>
    </div>
  );
};

export default Layout;

export const DummyContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="">
      <div className="info_container mx-auto ">{children}</div>
    </div>
  );
};
