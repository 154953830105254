import {
  getFirestore,
  doc,
  setDoc,
  onSnapshot,
  collection,
  addDoc,
  updateDoc,
} from "firebase/firestore";
import firebaseApp from "./firebase";
import { MessagePayload } from "./firebaseTypes";

const db = getFirestore(firebaseApp);

export const useSaveMessages = () => {
  return async (groupId: string, payload: MessagePayload) => {
    try {
      await addDoc(collection(db, "Messages", groupId, groupId), {
        ...payload,
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };
};

export const useNotifyConsultation = () => {
  return async (consultationId: string) => {
    try {
      await setDoc(doc(db, "Consultation", consultationId), {
        createdAt: new Date(),
        start: false,
        timer: false,
        "wait-room": true,
        mode: { doctor: null, patient: null },
        endConsultation: false,
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };
};

export const useListenToEndConsultation = () => {
  return async (consultationId: string, endConsultation: () => void) => {
    return onSnapshot(doc(db, "Consultation", consultationId), (docs) => {
      if (docs.data()?.endConsultation) {
        endConsultation();
      }
    });
  };
};

export const endConsultationOnFS = async (
  consultationId: string,
  callback: () => void
) => {
  try {
    await updateDoc(doc(db, "Consultation", consultationId), {
      endConsultation: true,
    });
    callback();
  } catch (e) {
    console.error("Error adding document: ", e);
    callback();
  }
};

export const useUpdateTimer = () => {
  return async (consultationId: string) => {
    try {
      await updateDoc(doc(db, "Consultation", consultationId), {
        timer: true,
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };
};

export const useOnsnapshot = () => {
  return async (
    consultationId: string,
    onTrigger: (contactMedium: string) => void,
    contactMedium: string,
    isDoctorJoined: boolean,
    refreshQuery: () => void
  ) => {
    return onSnapshot(doc(db, "Consultation", consultationId), (docs) => {
      if (docs.data()?.start === true && isDoctorJoined) {
        onTrigger(contactMedium);
      }
      if (docs.data()?.start === true && !isDoctorJoined) {
        refreshQuery();
      }
    });
  };
};

export const useListenToTimer = () => {
  return async (
    consultationId: string,
    onTrigger: () => void,
    contactMedium: string,
    isDoctorJoined: boolean
  ) => {
    return onSnapshot(doc(db, "Consultation", consultationId), (docs) => {
      if (docs.data()?.timer === true && isDoctorJoined) {
        onTrigger();
      }
    });
  };
};

export const useListenToChat = () => {
  return async (
    groupId: string,
    setMessages: React.Dispatch<
      React.SetStateAction<MessagePayload[] | undefined>
    >
  ) => {
    return onSnapshot(
      collection(db, "Messages", groupId, groupId),
      (querySnapshot) => {
        setMessages(undefined);
        querySnapshot.forEach((doc) => {
          setMessages((prev) => {
            return prev
              ? [doc.data() as MessagePayload, ...prev]
              : [doc.data() as MessagePayload];
          });
        });
      }
    );
  };
};

export const useListenToDoctorTyping = () => {
  return async (
    roomId: string,
    setDoctorTyping: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    return onSnapshot(doc(db, "Messages", roomId), (docs) => {
      const isDoctorTyping = docs.data()?.doctor_typing || false;
      setDoctorTyping(isDoctorTyping);
    });
  };
};

export const useListenToPatientTypingVar = () => {
  return async (
    roomId: string
    //setPatientTypingVar: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    let isTherePatientTypingVar = false;
    const unSubscribe = await onSnapshot(
      doc(db, "Messages", roomId),
      (docs) => {
        const patientTypingVar = docs.data()?.patient_typing;
        const noPatientTypingVar =
          patientTypingVar === undefined || patientTypingVar === null;
        isTherePatientTypingVar = !noPatientTypingVar;
        //setPatientTypingVar(isTherePatientTypingVarDoctor);
      }
    );

    return { unSubscribe, isTherePatientTypingVar };
  };
};

export const useUpdatePatientIsTyping = () => {
  return async (roomId: string, isPatientTyping: boolean) => {
    try {
      await updateDoc(doc(db, "Messages", roomId), {
        patient_typing: isPatientTyping,
      });
    } catch (e) {
      console.error("Error updating patient is typing: ", e);
    }
  };
};

export const checkAndUpdatePatientTypingVar = async (roomId: string) => {
  const listenToPatientTypingVar = useListenToPatientTypingVar();
  const updatePatientTyping = useUpdatePatientIsTyping();
  const { unSubscribe, isTherePatientTypingVar } =
    await listenToPatientTypingVar(roomId);
  if (!isTherePatientTypingVar) updatePatientTyping(roomId, false);
  return unSubscribe;
};
