import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import Call from "../components/Call/Call";
import { Timer } from "../components/Timer";
import { ConsultFactory } from "../utils/funcs";
import { preventClickBackMsgs } from "../libs/mock";
import { useGraphQLApi } from "../hooks/useGraphQLApi";
import { useLocation, useParams } from "react-router-dom";
import usePreventClickBack from "../hooks/usePreventClickBack";
import { useListenToTimer, useUpdateTimer } from "../utils/firestore";
import { useConsultationContext } from "../contexts/consultationContext";

const VideoAudioApp = () => {
  usePreventClickBack(preventClickBackMsgs.audioVideo);
  const { pathname } = useLocation();
  const appId = React.useRef(process.env.REACT_APP_AGORA_APP_ID || "").current;
  const { consultationId } = useParams();
  const listenToFBTimer = useListenToTimer();
  const startFirebaseTimer = useUpdateTimer();
  const contactMedium = pathname.split("/")[1];
  const { showAwaitingRes } = useContext(AppContext);
  const { patientJoinConsultation } = useGraphQLApi();
  const [joinedCall, setJoinedCall] = useState(false);
  const [timerEnded, setTimerEnded] = useState(false);
  const consultationDetails = useConsultationContext();
  const [startTimer, setStartTimer] = useState<boolean>(false);

  const agoraInfo = JSON.parse(
    `${sessionStorage.getItem("agoraInfo") || "{}"}`
  );
  const [now] = useState<number>(
    Number(localStorage.getItem("consultationStartTime"))
  );
  const { doctorData } = React.useMemo(
    () => ConsultFactory(consultationDetails),
    [consultationDetails]
  );
  const { firstName, lastName } = doctorData;

  const getCallType = (string: string) =>
    string === "video" ? "video" : string === "voice" ? "audio" : "video";

  useEffect(() => {
    if (consultationId && joinedCall) startFirebaseTimer(`${consultationId}`);
  }, [joinedCall]);

  useEffect(() => {
    if (consultationId && consultationDetails) {
      const unsubscribe = listenToFBTimer(
        consultationId,
        () => setStartTimer(true),
        consultationDetails?.contactMedium as string,
        typeof consultationDetails?.doctor === "string"
      );

      return () => {
        unsubscribe.then((unsubscribeFn) => unsubscribeFn());
      };
    }
  }, []);

  useEffect(() => {
    patientJoinConsultation(
      `${consultationId}`,
      () => setJoinedCall(true),
      "Patient failed to update updatedJoinedConsultation"
    );
  }, []);

  return (
    <>
      {joinedCall && (
        <div className=" w-full h-screen flex flex-col relative sm:bg-transparent lg:bg-[#313131]">
          <div className="w-full absolute lg:static z-50">
            <Timer
              startTimer={startTimer}
              deadline={now || 1800000 + Date.now()}
              onTimerEnd={() => setTimerEnded(true)}
              bgColor="bg-white"
              showTimeRunningOutWarning={true}
              color="text-primary"
              textAfterTimer="minutes remaining"
              threshold={{ mins: 4 }}
              name="consultationStartTime"
              persistType="local"
            />
            {showAwaitingRes && (
              <div className="flex justify-center mt-2">
                <div className=" bg-[#E4F2FF] rounded-lg p-4 text-center">
                  <p className=" font-semibold text-sm text-[#1A7ABF]">{`Waiting for Dr. ${firstName} ${lastName} to accept ...`}</p>
                </div>
              </div>
            )}
          </div>
          <div className=" flex-1 flex">
            {startTimer && (
              <Call
                appId={`${appId}`}
                channelName={`${consultationId}`}
                token={agoraInfo?.token || ""}
                uid={agoraInfo?.uid || ""}
                timerParams={{ timerEnded }}
                callType={getCallType(contactMedium)}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default VideoAudioApp;
