import React, { useEffect, useRef } from "react";

interface IntersectionObserverProps {
  root?: Element | null;
  rootMargin?: string;
  threshold?: number | number[];
  onIntersect: (entry: IntersectionObserverEntry) => void;
  children: React.ReactNode;
}

const IntersectionObserverComponent: React.FC<IntersectionObserverProps> = ({
  root = null,
  rootMargin = "0px",
  threshold = [0.5, 1, 0],
  onIntersect,
  children,
}) => {
  const targetRef = useRef<HTMLDivElement>(null);

  const callback = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      onIntersect(entry);
    });
  };

  const options = {
    root,
    rootMargin,
    threshold,
  };

  useEffect(() => {
    if (!targetRef.current && !root) return;
    const targetDiv = targetRef.current as unknown;
    const intersectionObserver = new IntersectionObserver(callback, options);
    intersectionObserver.observe(targetDiv as Element);

    return () => {
      if (intersectionObserver) {
        intersectionObserver.disconnect();
      }
    };
  }, [root, rootMargin, threshold, onIntersect]);

  return <div ref={targetRef}>{children}</div>;
};

export default IntersectionObserverComponent;
